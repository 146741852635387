import React from "react"
import { graphql, PageProps } from "gatsby"
import { SEO } from "../../components/SEO"
import { DefaultTemplatePageData, TemplateProps } from "../templates"
import RecordsRequest from "../../components/RecordsRequest/RecordsRequest"
const PublicRecordsRequestPage: React.FC<PageProps> = ({
  data,
  location,
}: TemplateProps<DefaultTemplatePageData>) => {
  const { title, content, template } = data.wpPage
  const { allWp } = data

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />

      <RecordsRequest
        title={title}
        content={content}
        template={template}
        socialLinks={allWp.nodes[0].siteSettings.socialLinks}
      />
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      slug
      template {
        ... on WpTemplate_RecordsRequest {
          templateName
        }
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      title
    }
    allWp {
      nodes {
        siteSettings {
          socialLinks {
            facebook
            instagram
            twitter
          }
        }
      }
    }
  }
`

export default PublicRecordsRequestPage
