import React from "react"
import tw from "twin.macro"
import RecordsRequestForm from "./PublicRecordRequestForm"

interface PageData {
  title: string
  content: string
  template: any
  socialLinks: any
}

const RecordsRequest: React.FC<PageData> = ({ template, title }) => {
  return (
    <section>
      {template && (
        <section
          css={tw`flex flex-col font-sans justify-center items-center mt-20 w-full`}
        >
          <div
            css={tw`flex flex-row flex-wrap justify-center items-center lg:items-start w-full`}
          >
            <div css={tw`w-full lg:w-3/5`}>
              <RecordsRequestForm resource={"contact"} title={title} />
            </div>
          </div>
        </section>
      )}
    </section>
  )
}

export default RecordsRequest
