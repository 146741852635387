import React, { useState, useContext, useEffect, useRef } from "react"
import { submitContactForm } from "../Contact/ContactSubmit"
import tw from "twin.macro"
import { LanguageContext } from "../../context/Language"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import TranslateClient from "../../clients/TranslateClient"

import { Alert, Button, Input, TextArea } from "../Form"
import ReCAPTCHA from "react-google-recaptcha"
import SectionHeading from "../SectionHeading/SectionHeading"

interface Props {
  title: string
}

const RecordsRequestForm: React.FC<Props> = ({ title, ...remainingProps }) => {
  const introText = `Please complete and submit this form to request public records under the Alabama Open
  Records Act. All fields must be filled completely and truthfully for the request to be
  processed.`
  const requestorHeading = "Requestor:"
  const requestorSubhead = "Requestor's contact information:"
  const namePlaceholder = "Full Name"
  const businessNamePlaceholder = "Company Name"
  const phonePlaceholder = "Phone Number"
  const emailPlaceholder = "Email Address"
  const streetPlaceholder = "Street Address"
  const cityPlaceholder = "City"
  const statePlaceholder = "State"
  const zipPlaceHolder = "Zip"
  const paymentFeesHeading = "Payment of Fees:"
  const paymentFeesText =
    "The AORA allows for the collection of fees related to the time and expense of providing records. I am willing to pay processing fees of an amount up to:"
  const paymentFeeAmountPlaceholder = "$ Payment Fee Amount"
  const recordsRequestHeading = "Specific records requested:"
  const recordsRequestSubhead =
    "Please be as specific as possible. Overly broad requests may take longer to process and result in increased fees."
  const messagePlaceholder = "Message"
  const signatureHeading =
    "By my signature below, I hereby agree to the terms and conditions applicable to obtaining public records from the Birmingham Airport Authority, including the requirement for advance payment, and, further, swear or affirm, to the best of my knowledge, that all information provided by me on this form is accurate, true, and correct."
  const signaturePlaceholder = "Signature (Type or Sign)"
  const [text, setText] = useState({
    requestorHeading,
    requestorSubhead,
    namePlaceholder,
    businessNamePlaceholder,
    phonePlaceholder,
    emailPlaceholder,
    streetPlaceholder,
    cityPlaceholder,
    statePlaceholder,
    zipPlaceHolder,
    paymentFeesHeading,
    paymentFeesText,
    paymentFeeAmountPlaceholder,
    recordsRequestHeading,
    recordsRequestSubhead,
    messagePlaceholder,
    signatureHeading,
    signaturePlaceholder,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        requestorHeading,
        requestorSubhead,
        namePlaceholder,
        businessNamePlaceholder,
        phonePlaceholder,
        emailPlaceholder,
        streetPlaceholder,
        cityPlaceholder,
        statePlaceholder,
        zipPlaceHolder,
        paymentFeesHeading,
        paymentFeesText,
        paymentFeeAmountPlaceholder,
        recordsRequestHeading,
        recordsRequestSubhead,
        messagePlaceholder,
        signatureHeading,
        signaturePlaceholder,
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          requestorHeading,
          requestorSubhead,
          namePlaceholder,
          businessNamePlaceholder,
          phonePlaceholder,
          emailPlaceholder,
          streetPlaceholder,
          cityPlaceholder,
          statePlaceholder,
          zipPlaceHolder,
          paymentFeesHeading,
          paymentFeesText,
          paymentFeeAmountPlaceholder,
          recordsRequestHeading,
          recordsRequestSubhead,
          messagePlaceholder,
          signatureHeading,
          signaturePlaceholder,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  const recaptchaRef = useRef(null)
  const [name, setName] = useState<string>("")
  const [businessName, setBusinessName] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [emailAddress, setEmailAddress] = useState<string>("")
  const [streetAddress, setStreetAddress] = useState<string>("")
  const [city, setCity] = useState<string>("")
  const [state, setState] = useState<string>("")
  const [zip, setZip] = useState<string>("")
  const [feeAmount, setFeeAmount] = useState<string>("")
  const [message, setMessage] = useState<string>("")
  const [signature, setSignature] = useState<string>("")

  const [error, setError] = useState<string>("")
  const [success, setSuccess] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const inputWrapperStyles = tw`my-2`
  const inputStyles = tw`w-full`

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const onSubmit = async (e: any) => {
    e?.preventDefault()
    setSubmitting(true)
    setSuccess(false)
    setError("")
    if (!validateEmail(emailAddress.trim())) {
      setError("Invalid email address")
      setSubmitting(false)
      return
    }
    const recaptchaValue = recaptchaRef?.current
      ? recaptchaRef.current?.getValue()
      : null
    const success = await submitContactForm({
      name: name.trim(),
      business_name: businessName.trim(),
      phone: phoneNumber.trim(),
      email: emailAddress.trim(),
      form_type: "contact",
      message:
        "(Public Records Request): " +
        "\n" +
        message.trim() +
        "\n" +
        "Address: " +
        streetAddress.trim() +
        " " +
        city.trim() +
        ", " +
        state.trim() +
        " " +
        zip.trim() +
        "\n" +
        "Fee Amount: $" +
        feeAmount.trim(),
      signature: signature.trim(),
      user_captcha_token: recaptchaValue,
    })
    setSuccess(success)
    setSubmitting(false)
    if (!success) {
      setError("Uh oh! Something went wrong.")
    } else {
      setName("")
      setBusinessName("")
      setPhoneNumber("")
      setEmailAddress("")
      setStreetAddress("")
      setCity("")
      setState("")
      setZip("")
      setFeeAmount("")
      setMessage("")
      setSignature("")
    }
  }

  return (
    <section {...remainingProps}>
      <div
        css={[
          tw`flex flex-col h-auto justify-center items-center w-full top-0  bg-cover bg-no-repeat font-sans leading-normal pt-8 px-4 max-w-[1100px] mx-auto`,
        ]}
      >
        <div css={[tw`flex justify-center items-center w-full mb-4`]}>
          <SectionHeading>{title}</SectionHeading>
        </div>
        <div css={[tw`text-lg w-5/6 lg:w-full`]}>
          <div css={[tw`mb-8`]}>
            <p>{introText}</p>
          </div>
          {error && (
            <Alert message={error} type="error" wrapperStyles={tw`mb-2`} />
          )}
          {success && !error && (
            <Alert
              message="Your request has been received!"
              type="success"
              wrapperStyles={tw`mb-2`}
            />
          )}
          <div css={tw`mt-3`}>
            <h4 css={tw`mb-2 underline`}>{requestorHeading}</h4>
            <p css={tw`leading-tight`}>{requestorSubhead}</p>
          </div>
          <form>
            <div css={tw`grid md:grid-cols-2 md:gap-x-2`}>
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-0`}
                placeholder={text?.namePlaceholder}
                onChange={e => setName(e.target.value)}
                required
                value={name}
              />
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-0`}
                placeholder={text?.businessNamePlaceholder}
                onChange={e => setBusinessName(e.target.value)}
                required
                value={businessName}
              />
            </div>

            <div css={tw`grid md:grid-cols-2 md:gap-x-2 md:-mt-1`}>
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-0`}
                placeholder={text?.phonePlaceholder}
                onChange={e => setPhoneNumber(e.target.value)}
                required
                value={phoneNumber}
              />
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-0`}
                placeholder={text?.emailPlaceholder}
                onChange={e => setEmailAddress(e.target.value)}
                required
                value={emailAddress}
              />
            </div>

            <div css={tw`grid md:-mt-1`}>
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full mt-0`}
                placeholder={text?.streetPlaceholder}
                onChange={e => setStreetAddress(e.target.value)}
                required
                value={streetAddress}
              />
            </div>

            <div css={tw`grid md:grid-cols-3 md:gap-x-2 md:-mt-1`}>
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-0`}
                placeholder={text?.cityPlaceholder}
                onChange={e => setCity(e.target.value)}
                required
                value={city}
              />
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-0`}
                placeholder={text?.statePlaceholder}
                onChange={e => setState(e.target.value)}
                required
                value={state}
              />
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-0`}
                placeholder={text?.zipPlaceHolder}
                onChange={e => setZip(e.target.value)}
                required
                value={zip}
              />
            </div>

            <div css={tw`my-10`}>
              <h4 css={tw`mb-2 underline`}>{paymentFeesHeading}</h4>
              <p css={tw`leading-tight`}>
                {paymentFeesText}{" "}
                <Input
                  wrapperStyles={inputWrapperStyles}
                  styles={inputStyles}
                  css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-[220px] my-0 mr-1`}
                  placeholder={text?.paymentFeeAmountPlaceholder}
                  onChange={e => setFeeAmount(e.target.value)}
                  required
                  value={feeAmount}
                />
              </p>
            </div>

            <div css={tw`mb-6`}>
              <h4 css={tw`mb-2 underline`}>{recordsRequestHeading}</h4>
              <p css={tw`leading-tight`}>{recordsRequestSubhead}</p>

              <TextArea
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                placeholder={text?.messagePlaceholder}
                rows={6}
                onChange={e => setMessage(e.target.value)}
                required
                value={message}
              />
            </div>

            <div css={tw`mb-7`}>
              <h4 css={tw`mb-2 leading-snug`}>{signatureHeading}</h4>
              <Input
                wrapperStyles={inputWrapperStyles}
                styles={inputStyles}
                css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans md:w-[33%]`}
                placeholder={text?.signaturePlaceholder}
                onChange={e => setSignature(e.target.value)}
                required
                value={signature}
              />
            </div>

            <div css={tw`md:flex md:justify-between md:items-center`}>
              <ReCAPTCHA
                css={tw`mb-5`}
                sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
              />
              <Button
                text="SEND"
                styles={tw`w-full md:w-auto mb-3 rounded-full`}
                css={tw`font-extraBold bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-1 h-48 resize-none`}
                disabled={submitting}
                onClick={e => onSubmit(e)}
                loading={submitting}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default RecordsRequestForm
