import axios from "axios"

export interface ContactFormEmailRequest {
  form_type: string
  name: string
  business_name: string
  email: string
  phone: string
  signature: string
  message: string
  user_captcha_token?: string
}

export const submitContactForm = async (request: ContactFormEmailRequest) => {
  try {
    const res = await fetch(`${process.env.GATSBY_BAA_API_URL}/email`, {
      method: "POST",
      headers: {
        "x-api-key": process.env.GATSBY_BAA_API_KEY,
      },
      body: JSON.stringify(request),
    })
    return res.ok
  } catch (err) {
    return false
  }
}
